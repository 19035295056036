import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

export type DMNavItemBaseProps = {
  iconSlot?: React.ReactNode
  itemClassNames?: string
  startSlot?: React.ReactNode
  textSlot?: React.ReactNode
  countSlot?: React.ReactNode
  endSlot?: React.ReactNode
  size?: "small" | "medium"
  ariaLabel?: string
  className?: string
  isDisabled?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export function DMNavItemBase({
  iconSlot,
  itemClassNames,
  startSlot,
  textSlot,
  countSlot,
  endSlot,
  className,
  ariaLabel,
  isDisabled,
  size = "medium",
}: DMNavItemBaseProps) {
  return (
    <div
      aria-label={ariaLabel}
      className={cn(
        "flex flex-auto items-center justify-between",
        {
          "h-[40px] px-12": size === "medium",
          "h-[32px] px-8": size === "small",
          "opacity-disabled": isDisabled,
        },
        className
      )}
    >
      <div
        className={cn(
          "flex flex-grow flex-shrink basis-0 items-center",
          itemClassNames,
          {
            "gap-12": size === "medium",
            "gap-8": size === "small",
          }
        )}
      >
        {iconSlot && <div>{iconSlot}</div>}
        {startSlot && <div>{startSlot}</div>}
        {textSlot}
      </div>
      {countSlot && <div>{countSlot}</div>}
      {endSlot && <div>{endSlot}</div>}
    </div>
  )
}
