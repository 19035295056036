"use client"

import * as React from "react"
import { createContext, useMemo } from "react"

import {
  ComboboxList as ComboboxListPrimitive,
  ComboboxListProps as ComboboxListPropsPrimitive,
} from "@ariakit/react"

import { cn } from "../../../utils/cn"

import { UpdateParentSelectionStates } from "./types"

export interface DMListboxTreeProps
  extends Omit<
    ComboboxListPropsPrimitive,
    "disabled" | "focusable" | "children"
  > {
  isDisabled?: boolean
  isFocusable?: boolean
  children: React.ReactNode
  allItemsWithState?: UpdateParentSelectionStates
  /** Highlights search value in a label */
  highlightSearch?: boolean
}

export interface DMListboxTreeContextType {
  allItems: UpdateParentSelectionStates
  highlightSearch?: boolean
}

// Context is needed to pass down some grid properties down to the Row
export const DMListboxTreeContext = createContext<DMListboxTreeContextType>({
  allItems: {
    items: [],
    indeterminateItems: [],
    allChildrenSelectedItems: [],
    expandedItems: [],
  },
  highlightSearch: true,
})

const DMListboxTree = React.forwardRef<
  React.ElementRef<typeof ComboboxListPrimitive>,
  DMListboxTreeProps
>(
  (
    {
      children,
      isDisabled,
      isFocusable,
      allItemsWithState = {
        items: [],
        indeterminateItems: [],
        allChildrenSelectedItems: [],
        expandedItems: [],
      },
      className,
      highlightSearch = true,
      ...props
    },
    ref
  ) => {
    const memoizedContext: DMListboxTreeContextType = useMemo(
      () => ({ allItems: allItemsWithState, highlightSearch }),
      [allItemsWithState, highlightSearch]
    )

    return (
      <DMListboxTreeContext.Provider value={memoizedContext}>
        <ComboboxListPrimitive
          ref={ref}
          alwaysVisible
          disabled={isDisabled}
          focusable={isFocusable}
          className={cn(
            "pr-8 h-full overflow-auto scrollbar-fancy scrollbar-stable",
            "h-[450px] max-h-[40vh]",
            className
          )}
          render={<ul />}
          {...props}
        >
          {children}
        </ComboboxListPrimitive>
      </DMListboxTreeContext.Provider>
    )
  }
)

DMListboxTree.displayName = "DMListboxTree"

export { DMListboxTree }
