import * as React from "react"
import { SVGProps } from "react"

export function SupernovaLogoSymbol(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height={16}
      viewBox="0 0 16 16"
      width={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.4 11.267c.2 2.533 1.533 4.4 3.267 4.4 1.733 0 3.066-1.867 3.266-4.4 2.534-.2 4.4-1.534 4.4-3.267 0-1.733-1.866-3.067-4.4-3.267-.2-2.533-1.533-4.4-3.266-4.4C5.933.333 4.6 2.2 4.4 4.733 1.867 4.933 0 6.267 0 8c0 1.733 1.867 3.067 4.4 3.267Zm4.667 2.2c-.2.4-.734 1-1.4 1-.667 0-1.134-.6-1.4-1-.334-.6-.6-1.334-.667-2.2 1.8-.134 3.267-.934 3.933-2 .134.466.134.933.134 1.4a5.276 5.276 0 0 1-.6 2.8ZM6.6 8c0-.6.467-1.067 1.067-1.067.6 0 1.066.467 1.066 1.067 0 .6-.466 1.067-1.066 1.067C7.067 9.067 6.6 8.6 6.6 8Zm6.533-1.4c.4.2 1 .733 1 1.4 0 .667-.6 1.133-1 1.4-.6.333-1.333.6-2.2.667-.133-1.8-.933-3.267-2-3.934C9.4 6 9.867 6 10.333 6a5.276 5.276 0 0 1 2.8.6ZM6.267 2.533c.2-.4.733-1 1.4-1 .666 0 1.133.6 1.4 1 .333.6.6 1.334.666 2.2-1.8.134-3.266.934-3.933 2a5.075 5.075 0 0 1-.2-1.4c0-1.066.267-2.066.667-2.8ZM2.2 6.6c.6-.333 1.333-.6 2.2-.667.133 1.8.933 3.267 2 3.934C5.933 10 5.467 10 5 10c-1.067 0-2.067-.267-2.8-.667-.333-.2-1-.666-1-1.333 0-.667.667-1.133 1-1.4Z"
        fill="currentColor"
      />
    </svg>
  )
}
