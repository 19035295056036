import React from "react"

import { cn } from "../../utils/cn"
import { getFirstLetters } from "../../utils/getFirstLetters"
import { DMTooltip } from "../DMTooltip"

import { Image } from "reshaped/bundle"

export const avatarAvailableColors = [
  "grey",
  "blue",
  "cyan",
  "red",
  "fuchsia",
  "green",
  "lightGrey",
  "yellow",
  "orange",
  "pink",
  "purple",
  "teal",
] as const

export type UserAvatarColor = (typeof avatarAvailableColors)[number]

export type DMUserAvatarProps = {
  size?: "tiny" | "small" | "medium" | "large" | "huge"
  className?: string
  name?: string
  color?: UserAvatarColor
  hasTooltip?: boolean
  avatarImage?: string | null
  tooltipText?: string
  id?: string
}

export const avatarColorValues: Record<UserAvatarColor, string> = {
  grey: "#4E536A",
  blue: "#0F62FE",
  cyan: "#0E7490",
  fuchsia: "#B725CB",
  green: "#05751F",
  lightGrey: "#68718D",
  yellow: "#FAC300",
  orange: "#C2410C",
  pink: "#D12372",
  purple: "#874CE6",
  teal: "#107A71",
  red: "#CB101D",
}

export const getAvatarTextClassnameForColor = (colorType?: UserAvatarColor) => {
  if (!colorType) {
    return ""
  }

  if (colorType === "yellow") {
    return "text-black"
  }

  return "text-white"
}

export const sizeClassnames: Record<
  Required<DMUserAvatarProps>["size"],
  string
> = {
  tiny: "h-[16px] w-[16px] text-[7px] rounded-small",
  small: "h-[20px] w-[20px] text-[8px] rounded-small",
  medium: "h-[24px] w-[24px] text-[10px] rounded",
  large: "h-[40px] w-[40px] text-[14px] rounded-medium",
  huge: "h-[64px] w-[64px] text-[20px] rounded-large",
}

export const getAvatarColorByUserId = (id?: string) => {
  const idNumber = Number.parseInt(id ?? "", 10)
  const defaultColor = avatarAvailableColors[0]
  if (idNumber) {
    return (
      avatarAvailableColors[idNumber % avatarAvailableColors.length] ??
      defaultColor
    )
  }
  return defaultColor
}

type DMUserAvatarBaseProps = Pick<
  DMUserAvatarProps,
  "size" | "hasTooltip" | "className" | "color"
> & {
  tooltipText?: string
  children: React.ReactNode
}

export function DMUserAvatarBase({
  hasTooltip,
  tooltipText,
  size = "medium",
  className = "",
  color,
  children,
}: DMUserAvatarBaseProps) {
  return (
    <DMTooltip
      delayDuration={0}
      isDisabled={!hasTooltip}
      side="bottom"
      textAlign="left"
      text={tooltipText ?? ""}
    >
      <div
        className={cn(
          sizeClassnames[size],
          "flex items-center justify-center cursor-default overflow-hidden font-bold",
          getAvatarTextClassnameForColor(color),
          className
        )}
        style={{
          backgroundColor: color && avatarColorValues[color],
        }}
      >
        {children}
      </div>
    </DMTooltip>
  )
}

export function DMUserAvatar({
  id,
  name = "Unknown",
  color = "grey",
  avatarImage,
  className,
  tooltipText,
  ...restProps
}: DMUserAvatarProps) {
  const userColor = id ? getAvatarColorByUserId(id) : color
  return (
    <DMUserAvatarBase
      tooltipText={tooltipText ?? name}
      className={className}
      color={!avatarImage ? userColor : undefined}
      {...restProps}
    >
      {avatarImage ? (
        <Image
          displayMode="cover"
          height="100%"
          width="100%"
          src={avatarImage}
          alt={name}
          className="cover"
        />
      ) : (
        <div>{getFirstLetters(name)?.toUpperCase()}</div>
      )}
    </DMUserAvatarBase>
  )
}
