"use client"

import React from "react"

import {
  DMUserAvatar,
  DMUserAvatarBase,
  DMUserAvatarProps,
} from "../DMUserAvatar/DMUserAvatar"

import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion"

export type DMUserAvatarData = Pick<
  DMUserAvatarProps,
  "id" | "name" | "avatarImage" | "color" | "tooltipText"
> & {
  /**
   * unique key used for animation. Needed because there can be same users in the stack - different session
   */
  uniqueKey: string | number
}

export type DMUserAvatarStackProps = {
  users: DMUserAvatarData[]
  maxUsers?: number
} & Pick<DMUserAvatarProps, "size" | "hasTooltip">

const animationProps: HTMLMotionProps<"div"> = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    damping: 15,
    stiffness: 200,
  },
}

export function DMUserAvatarStack({
  users,
  size,
  hasTooltip,
  maxUsers = 4,
}: DMUserAvatarStackProps) {
  const effectiveMaxUsers = Math.min(users.length, maxUsers)
  const showedUsers = users.slice(0, effectiveMaxUsers)
  const otherUsers = users.slice(effectiveMaxUsers)
  return (
    <div className="flex gap-4">
      <AnimatePresence>
        {showedUsers.map(({ uniqueKey, ...resUserProps }) => (
          <motion.div key={uniqueKey} {...animationProps}>
            <DMUserAvatar
              key={uniqueKey}
              size={size}
              hasTooltip={hasTooltip}
              {...resUserProps}
            />
          </motion.div>
        ))}
        {otherUsers.length > 0 && (
          <motion.div key="count" {...animationProps}>
            <DMUserAvatarBase
              hasTooltip={hasTooltip}
              size={size}
              className="bg-decorative-grey text-neutral"
              tooltipText={
                hasTooltip
                  ? otherUsers.map(({ name }) => name).join("\n")
                  : undefined
              }
            >
              +{users.length - effectiveMaxUsers}
            </DMUserAvatarBase>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
