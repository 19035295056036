import React from "react"

import { ActionableRef } from "../../Actionable/Actionable.types"
import { Button } from "../../primitives/Button"
import { DMButtonBaseProps } from "../../primitives/Button/Button.types"
import { DecorativeClasses } from "../DMButton.types"

// extend DMButtonBaseProps type with color property, omitting variant property
export interface DMButtonDecorativeProps
  extends Omit<DMButtonBaseProps, "variant"> {
  /* Decorative color, allows for all decorative colors from DecorativeClasses */
  color?: keyof DecorativeClasses
}

function DMButtonDecorativeBase(
  props: DMButtonDecorativeProps,
  ref: ActionableRef
) {
  const {
    size = "medium",
    color = "lightGrey",
    isFullWidth = false,
    isLoading = false,
    isHighlighted = false,
    children,
    ...rest
  } = props

  const variantClasses: DecorativeClasses = {
    blue: {
      base: "bg-decorative-blue text-decorative-blue",
      hover: "hover:border-decorative-blue",
      focus: "focus-visible:ring-2",
    },
    green: {
      base: "bg-decorative-green text-decorative-green",
      hover: "hover:border-decorative-green",
      focus: "focus-visible:ring-2",
    },
    orange: {
      base: "bg-decorative-orange text-decorative-orange",
      hover: "hover:border-decorative-orange",
      focus: "focus-visible:ring-2",
    },
    purple: {
      base: "bg-decorative-purple text-decorative-purple",
      hover: "hover:border-decorative-purple",
      focus: "focus-visible:ring-2",
    },
    red: {
      base: "bg-decorative-red text-decorative-red",
      hover: "hover:border-decorative-red",
      focus: "focus-visible:ring-2",
    },
    teal: {
      base: "bg-decorative-teal text-decorative-teal",
      hover: "hover:border-decorative-teal",
      focus: "focus-visible:ring-2",
    },
    yellow: {
      base: "bg-decorative-yellow text-decorative-yellow",
      hover: "hover:border-decorative-yellow",
      focus: "focus-visible:ring-2",
    },
    cyan: {
      base: "bg-decorative-cyan text-decorative-cyan",
      hover: "hover:border-decorative-cyan",
      focus: "focus-visible:ring-2",
    },
    pink: {
      base: "bg-decorative-pink text-decorative-pink",
      hover: "hover:border-decorative-pink",
      focus: "focus-visible:ring-2",
    },
    grey: {
      base: "bg-decorative-grey text-decorative-grey",
      hover: "hover:border-decorative-grey",
      focus: "focus-visible:ring-2",
    },
    fuchsia: {
      base: "bg-decorative-fuchsia text-decorative-fuchsia",
      hover: "hover:border-decorative-fuchsia",
      focus: "focus-visible:ring-2",
    },
    lightGrey: {
      base: "bg-decorative-light-grey text-decorative-light-grey",
      hover: "hover:border-decorative-light-grey",
      focus: "focus-visible:ring-2",
    },
  }

  return (
    <Button
      ref={ref}
      isFullWidth={isFullWidth}
      isHighlighted={isHighlighted}
      isLoading={isLoading}
      size={size}
      variant="solid"
      variantStyles={variantClasses[color]}
      {...rest}
    >
      {children || ""}
    </Button>
  )
}

const DMButtonDecorative = React.forwardRef(
  DMButtonDecorativeBase
) as React.ForwardRefExoticComponent<DMButtonDecorativeProps>

DMButtonDecorative.displayName = "DMButtonDecorative"

export { DMButtonDecorative }
