"use client"

import React from "react"

import { DMLink } from "@supernovaio/dm"

import suspendedSvg from "@supernovaio/assets/illustrations/suspended.svg"
import upgradeDefaultSvg from "@supernovaio/assets/illustrations/upgrade_default.svg"
import upgradeToCompanySvg from "@supernovaio/assets/illustrations/upgrade_to_company.svg"
import upgradeToEnterpriseSvg from "@supernovaio/assets/illustrations/upgrade_to_enterprise.svg"
import upgradeToTeamSvg from "@supernovaio/assets/illustrations/upgrade_to_team.svg"
import { IconChevronRight } from "@supernovaio/icons"

import { cn } from "../../utils/cn"
import { DMImage } from "../DMImage"

import { SupportedProductCode } from "./DMBanner.types"

import { Card, classNames } from "reshaped/bundle"

export type DMBannerLinkType = {
  actionText: string
  href?: string
  isExternalLink?: boolean
  onClick?: () => void
}

export type DMBannerVariant = "regular" | "inline" | "compact"

type DMBannerBaseProps = {
  title: string
  variant?: DMBannerVariant
  subtitle?: React.ReactNode
  links?: DMBannerLinkType[] | null
  isLoading?: boolean
}

type DMBannerUpgradeProps = DMBannerBaseProps & {
  type: "upgrade"
  promotedProductCode?: SupportedProductCode
}

type DMBannerSuspendedProps = DMBannerBaseProps & {
  type: "suspended"
}

type DMBannerProps = DMBannerUpgradeProps | DMBannerSuspendedProps

const typeLinkVariantMapping = {
  upgrade: "upgrade",
  suspended: "warning",
  feature: "primary",
} as const

export function DMBanner(props: DMBannerProps) {
  const { type, title, subtitle, links, isLoading, variant = "regular" } = props

  let theme: {
    borderColor: string
    bgColor: string
    illustration: React.ReactElement
    textColor: string
  }

  if (type === "upgrade") {
    const { promotedProductCode } = props

    const productCodeAssets = {
      team: <DMImage image={upgradeToTeamSvg} />,
      company: <DMImage image={upgradeToCompanySvg} />,
      enterprise: <DMImage image={upgradeToEnterpriseSvg} />,
    }

    theme = {
      bgColor: "bg-upgrade-faded",
      textColor: "text-upgrade-faded",
      borderColor: "border-upgrade-faded",
      illustration: promotedProductCode ? (
        productCodeAssets[promotedProductCode]
      ) : (
        <DMImage image={upgradeDefaultSvg} />
      ),
    }
  } else {
    theme = {
      bgColor: "bg-warning-faded",
      textColor: "text-warning-faded",
      borderColor: "border-warning-faded",
      illustration: <DMImage image={suspendedSvg} />,
    }
  }

  return (
    <Card
      className={classNames("rounded-medium", theme.borderColor)}
      padding={0}
    >
      <div className={classNames("relative", theme.bgColor)}>
        <div
          className={cn(
            "flex gap-8",
            variant === "regular" && "flex-col p-16",
            variant === "inline" && "flex-row gap-12 justify-between p-12",
            variant === "compact" && "flex flex-col p-16"
          )}
        >
          <div
            className={cn(
              "flex flex-col gap-4",
              variant === "regular" && "pr-[140px]"
            )}
          >
            <p key="title" className="text-body font-semibold">
              {title}
            </p>
            {subtitle && (
              <p key="subtitle" className="text-body text-neutral-faded">
                {subtitle}
              </p>
            )}
          </div>
          <div className="flex flex-row gap-12">
            {links?.length &&
              links.map(
                ({ href, isExternalLink, onClick, actionText }, index) => (
                  <DMLink
                    key={`link-${index}`}
                    href={href}
                    iconEnd={IconChevronRight}
                    isDisabled={isLoading}
                    isExternalLink={isExternalLink}
                    variant={typeLinkVariantMapping[type]}
                    onClick={onClick}
                    innerClassName={cn(
                      variant === "compact" && "whitespace-nowrap"
                    )}
                  >
                    {actionText}
                  </DMLink>
                )
              )}
          </div>
        </div>
        {variant === "regular" && (
          <div className="absolute -bottom-[42px] -right-[42px]">
            {theme.illustration}
          </div>
        )}
      </div>
    </Card>
  )
}
