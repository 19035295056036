import * as React from "react"
import { SVGProps } from "react"

export function SupernovaLogoFull(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="none"
      height={16}
      viewBox="0 0 80 16"
      width={80}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg
        fill="none"
        height="16"
        width="80"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="currentColor">
          <path
            clipRule="evenodd"
            d="M7.667 15.667c-1.734 0-3.067-1.867-3.267-4.4C1.867 11.067 0 9.733 0 8c0-1.733 1.867-3.067 4.4-3.267.2-2.533 1.533-4.4 3.267-4.4 1.733 0 3.066 1.867 3.266 4.4 2.534.2 4.4 1.534 4.4 3.267 0 1.733-1.866 3.067-4.4 3.267-.2 2.533-1.533 4.4-3.266 4.4Zm0-1.2c.666 0 1.2-.6 1.4-1 .4-.734.666-1.734.6-2.8 0-.467 0-.934-.134-1.4-.666 1.066-2.133 1.866-3.933 2 .067.866.333 1.6.667 2.2.266.4.733 1 1.4 1Zm0-7.534C7.067 6.933 6.6 7.4 6.6 8c0 .6.467 1.067 1.067 1.067.6 0 1.066-.467 1.066-1.067 0-.6-.466-1.067-1.066-1.067ZM14.133 8c0-.667-.6-1.2-1-1.4a5.276 5.276 0 0 0-2.8-.6c-.466 0-.933 0-1.4.133 1.067.667 1.867 2.134 2 3.934a5.342 5.342 0 0 0 2.2-.667c.4-.267 1-.733 1-1.4ZM7.667 1.533c-.667 0-1.2.6-1.4 1-.4.734-.667 1.734-.667 2.8 0 .467.067.934.2 1.4.667-1.066 2.133-1.866 3.933-2a5.342 5.342 0 0 0-.666-2.2c-.267-.4-.734-1-1.4-1ZM4.4 5.933c-.867.067-1.6.334-2.2.667a12.1 12.1 0 0 1-.084.067C1.766 6.94 1.2 7.386 1.2 8c0 .667.667 1.133 1 1.333.733.4 1.733.667 2.8.667.467 0 .933 0 1.4-.133-1.067-.667-1.867-2.134-2-3.934Z"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M47.067 8.533h-5.4c.2 1.2 1.066 1.734 2.2 1.734.8 0 1.533-.334 1.933-1l1.067.4c-.6 1.133-1.8 1.733-3.067 1.733-1.867 0-3.4-1.4-3.4-3.467 0-2.066 1.533-3.466 3.4-3.466s3.267 1.333 3.267 3.466c.044.134.03.267.014.4-.007.067-.014.134-.014.2Zm-1.2-1.066c-.134-1.134-.934-1.734-2.067-1.734s-1.933.6-2.133 1.734h4.2Z"
            fillRule="evenodd"
          />
          <path d="M20.933 9.333H19.8c.067 1.4 1.267 2.134 2.4 2.134 1.4 0 2.6-.734 2.6-2 0-.8-.333-1.6-1.733-1.934l-1-.266c-.467-.134-.867-.334-.867-.8 0-.467.4-.867 1.067-.867.533 0 1.066.333 1.066.867h1.2c0-1.334-1.133-1.934-2.266-1.934C20.933 4.533 20 5.4 20 6.467c0 1.066.6 1.666 1.733 1.933l.934.2c.533.133.933.4.933.867 0 .533-.467.933-1.333.933-.8 0-1.334-.467-1.334-1.067Z" />
          <path d="M31.867 11.333V4.667h-1.2v3.6C30.6 9.667 29.533 10.2 28.6 10.2c-.933 0-1.6-.667-1.6-1.8V4.667h-1.267v3.866c0 1.867 1 2.934 2.667 2.934.733 0 1.8-.4 2.2-1.2v1.066h1.267Z" />
          <path d="M51.133 5.733c.2 0 .6 0 .934.2V4.667c-.2-.067-.6-.134-.934-.134-.866 0-1.533.534-1.866 1.667V4.667H48v6.666h1.267V8.867c0-1.734.533-3.134 1.866-3.134Z" />
          <path d="M59 7.467v3.866h-1.267V7.6c0-1.133-.666-1.8-1.6-1.8-.933 0-2 .533-2 1.933v3.6h-1.266V4.667h1.266v1c.4-.8 1.467-1.134 2.2-1.134C58 4.533 59 5.6 59 7.467Z" />
          <path
            clipRule="evenodd"
            d="M63.2 4.533c-1.867 0-3.467 1.334-3.467 3.467 0 2.067 1.6 3.467 3.467 3.467S66.667 10.133 66.667 8s-1.6-3.467-3.467-3.467Zm-.067 1.2C64.4 5.733 65.4 6.667 65.4 8c0 1.333-1 2.267-2.267 2.333-1.2 0-2.2-.933-2.2-2.333 0-1.333 1-2.267 2.2-2.267Z"
            fillRule="evenodd"
          />
          <path d="M70.4 11.333h-1.2l-2.733-6.666H67.8l2.067 5.066 2-5.066H73.2l-2.8 6.666Z" />
          <path
            clipRule="evenodd"
            d="M78.733 11.333H80V4.667h-1.2v1.066c-.467-.8-1.4-1.2-2.4-1.2-1.867 0-3.333 1.334-3.333 3.467 0 2.067 1.533 3.467 3.333 3.467.933 0 1.867-.4 2.333-1.2v1.066Zm-2.266-5.6c1.133 0 2.266.8 2.266 2.267 0 1.4-1.066 2.267-2.266 2.333-1.267 0-2.2-1-2.2-2.333 0-1.333 1-2.267 2.2-2.267Z"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M39.733 8c0 2.133-1.466 3.467-3.333 3.467-1 0-1.933-.4-2.4-1.2V14.8h-1.267V4.667H34v1.066c.467-.8 1.4-1.2 2.333-1.2 1.867 0 3.4 1.4 3.4 3.467Zm-1.266 0c0-1.333-.934-2.333-2.2-2.333C35.067 5.733 34 6.6 34 8c0 1.467 1.133 2.267 2.267 2.267 1.2 0 2.2-.934 2.2-2.267Z"
            fillRule="evenodd"
          />
        </g>
      </svg>
    </svg>
  )
}
