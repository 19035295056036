import React from "react"

import type { DMImportedImage } from "../../types/image"

type Props = {
  image: DMImportedImage
  alt?: string
  className?: string
}

/** For internal use only */
export function DMImage({ image, alt, className }: Props) {
  return (
    <img
      alt={alt || ""}
      className={className}
      height={typeof image === "object" ? image.height : undefined}
      src={typeof image === "string" ? image : image.src}
      width={typeof image === "object" ? image.width : undefined}
      loading="lazy"
      decoding="async"
    />
  )
}
