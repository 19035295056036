"use client"

import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useId,
} from "react"

import {
  DMButtonWhite,
  DMTextInput,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuTrigger,
  normalizeTestId,
} from "@supernovaio/dm"

import { IconMore } from "@supernovaio/icons"

import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

import { cn } from "../../utils/cn"

import { DMTabListContextDeprecated } from "./DMTabList"

const InputHorizontalPadding = 16

export type DMTabDeprecatedProps = {
  id?: string
  title?: string
  active?: boolean
  dropdownMenu?: React.ReactNode
  children: React.ReactNode
  isBeingEdited?: boolean
  onClick?: () => void
  onDoubleClick?: () => void
  onTitleChange?: (newTitle: string) => void
  onEditEnd?: () => void
  onPointerDown?: React.PointerEventHandler<HTMLInputElement>
  initialWidth?: number
  className?: string
  size?: "small" | "default"
  variant?: "neutral" | "primary"
  parentSortable?: ReturnType<typeof useSortable>
  dataTestId?: string
}

export function DMTabDeprecated({
  id,
  title,
  active,
  dropdownMenu,
  children,
  isBeingEdited,
  onClick,
  onDoubleClick,
  onTitleChange,
  onEditEnd,
  onPointerDown,
  initialWidth = 74,
  size = "default",
  variant = "primary",
  className,
  dataTestId,
  parentSortable,
}: DMTabDeprecatedProps) {
  const [dropdownVisible, setDropdownVisible] = React.useState(false)
  const tabDivRef = React.useRef<HTMLDivElement>(null)
  const { requestScrollToView } = React.useContext(DMTabListContextDeprecated)

  // Snapshots the title coming from props, if it changes, change the state
  // Needed because onBlur should revert to the original value
  const resolvedTitle = title?.length ? title : "Untitled"
  const [value, setValue] = useState(resolvedTitle)
  const [prevValue, setPrevValue] = useState(resolvedTitle)
  if (prevValue !== resolvedTitle) {
    setValue(resolvedTitle)
    setPrevValue(resolvedTitle)
  }

  const inputRef = useRef<HTMLInputElement | null>(null)

  // Focuses the input on mount
  useEffect(() => {
    if (isBeingEdited) {
      inputRef.current?.select()
    }
  }, [isBeingEdited])

  useEffect(() => {
    if (active && tabDivRef.current) {
      requestScrollToView(tabDivRef.current)
    }
  }, [active, requestScrollToView])

  const generatedId = useId()
  const tabSortable = useSortable({
    id: id ?? generatedId,
    disabled: !!parentSortable,
  })

  const sortable = parentSortable ?? tabSortable

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = sortable

  const onEditCancel = () => {
    setDropdownVisible(false)
    onEditEnd?.()
  }

  // Used to automatically resize the input by having an invisible span with the current title
  // value and setting it's width as input width
  const spanRef = useRef<HTMLSpanElement | null>(null)
  const [inputWidth, setInputWidth] = useState(initialWidth)

  useLayoutEffect(() => {
    if (spanRef.current && isBeingEdited) {
      setInputWidth(
        Math.max(
          spanRef.current.getBoundingClientRect().width +
            InputHorizontalPadding +
            1,
          16 // min-width
        )
      )
    }
  }, [value, isBeingEdited, initialWidth])

  useEffect(() => {
    if (isBeingEdited) {
      tabDivRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      })
    }
  }, [isBeingEdited])

  return (
    <div
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onKeyDown={({ key }) => {
        if (key === "Enter") {
          onClick?.()
        }
      }}
      ref={setNodeRef}
      style={{
        transform: CSS.Translate.toString(transform),
        transition,
        zIndex: isDragging ? "100" : "auto",
        opacity: isDragging ? 0.3 : 1,
      }}
      className={cn("focus:outline-none", className)}
      contentEditable={false}
      {...listeners}
      {...attributes}
      role="button"
      tabIndex={0}
      data-test-id={normalizeTestId(dataTestId)}
    >
      <div ref={tabDivRef} className="group/dm-tab relative flex flex-col">
        <div className="flex flex-col pt-4">
          {isBeingEdited && onTitleChange && onEditEnd ? (
            <div className="my-2">
              <span
                ref={spanRef}
                className="opacity-0 fixed -z-2 top-0 left-0 whitespace-pre-wrap"
              >
                {value}
              </span>
              <DMTextInput
                ref={inputRef}
                className="h-[26px] font-semibold"
                containerClassname="h-[26px] mb-2"
                value={value}
                style={{
                  width: inputWidth,
                  caretColor: "currentColor",
                }}
                onChange={(event) => {
                  setValue(event.target.value)
                }}
                onBlur={(event) => {
                  onTitleChange(event.currentTarget.value)
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onTitleChange(event.currentTarget.value)
                    setDropdownVisible(false)
                  } else if (event.key === "Escape") {
                    onEditCancel()
                  }
                }}
                onPointerDown={onPointerDown}
              />
            </div>
          ) : (
            <div
              className={cn(
                "font-semibold",
                // this should be rewritten to use cva
                active && variant === "primary" && "text-primary",
                !active && variant === "neutral" && "text-neutral-faded",
                ((active && variant === "neutral") ||
                  (!active && variant === "primary")) &&
                  "text-neutral"
              )}
            >
              {children}
            </div>
          )}
          <div className="px-8">
            <div
              className={cn(
                "visible h-[2px] shrink-0 rounded relative",
                variant === "neutral"
                  ? "bg-[var(--rs-color-foreground-neutral)]"
                  : "bg-[var(--rs-color-foreground-primary)]", // hack, because theming doesn't support it currently
                size === "small" ? "-bottom-1" : "-bottom-2",
                {
                  visible: active,
                  invisible: !active,
                }
              )}
              role="none"
            />
          </div>
        </div>
        {dropdownMenu && !isBeingEdited && (
          <DropdownMenu onOpenChange={setDropdownVisible}>
            <DropdownMenuTrigger>
              <DMButtonWhite
                noShadow
                className={cn(
                  "group-hover/dm-tab absolute left-[50%] translate-x-[-50%] ",
                  "top-[1px] max-h-[8px] max-w-[20px] bg-white outline-white text-neutral-faded",
                  "group-hover/dm-tab:visible hover:bg-primary hover:text-on-primary hover:outline hover:outline-2",
                  {
                    invisible: !dropdownVisible,
                    visible: dropdownVisible,
                  }
                )}
                icon={IconMore}
                size="small"
              />
            </DropdownMenuTrigger>
            <DropdownMenuPortal>
              <DropdownMenuContent>{dropdownMenu}</DropdownMenuContent>
            </DropdownMenuPortal>
          </DropdownMenu>
        )}
      </div>
    </div>
  )
}
