import * as React from "react"

import {
  ComboboxList as ComboboxListPrimitive,
  ComboboxListProps as ComboboxListPropsPrimitive,
} from "@ariakit/react"

import { cn } from "../../utils/cn"

export interface DMListboxListProps
  extends Omit<
    ComboboxListPropsPrimitive,
    "disabled" | "focusable" | "children"
  > {
  isDisabled?: boolean
  isFocusable?: boolean
  height?: number
  isVirtualized?: boolean
  children: React.ReactNode
  innerContainerClassName?: string
}

const DMListboxList = React.forwardRef<
  React.ElementRef<typeof ComboboxListPrimitive>,
  DMListboxListProps
>(
  (
    {
      children,
      isDisabled,
      isFocusable,
      isVirtualized = false,
      height = 460,
      innerContainerClassName,
      ...props
    },
    ref
  ) => {
    return (
      <ComboboxListPrimitive
        ref={ref}
        alwaysVisible
        disabled={isDisabled}
        focusable={isFocusable}
        className={cn("h-full", props.className)}
        {...props}
      >
        <div
          className={cn(
            "pr-4 h-full",
            !isVirtualized && "overflow-auto scrollbar-fancy scrollbar-stable",
            innerContainerClassName
          )}
        >
          {children}
        </div>
      </ComboboxListPrimitive>
    )
  }
)

DMListboxList.displayName = "DMListboxList"

export { DMListboxList }
