"use client"

import React, { useCallback, useEffect, useRef } from "react"

import { IconArrowLeft, IconArrowRight } from "@supernovaio/icons"

import { cn } from "../../utils/cn"
import { DMButtonWhite } from "../DMButtons"

export const DMTabListContextDeprecated = React.createContext<{
  requestScrollToView: (element: HTMLDivElement) => void
}>({ requestScrollToView: () => {} })

export function DMTabListDeprecated({
  className,
  endSlot,
  scrollable,
  children,
}: {
  className?: string
  endSlot?: React.ReactNode
  scrollable?: boolean
  children?: React.ReactNode
}) {
  const scrolledAlreadyRef = React.useRef(false)
  const tabsContainerRef = React.useRef<HTMLDivElement>(null)

  const handleScrollToViewRequest = React.useCallback(
    (element: HTMLDivElement) => {
      if (!scrolledAlreadyRef.current && scrollable) {
        scrolledAlreadyRef.current = true
        element.parentElement?.scrollTo({ left: element.offsetLeft })
      }
    },
    [scrollable]
  )

  const contextValue = React.useMemo(
    () => ({ requestScrollToView: handleScrollToViewRequest }),
    [handleScrollToViewRequest]
  )

  const tabsRef = useRef<HTMLDivElement>(null)
  const leftArrowRef = useRef<HTMLButtonElement>(null)
  const rightArrowRef = useRef<HTMLButtonElement>(null)

  const checkForScroll = useCallback(() => {
    const container = tabsRef.current
    if (container && scrollable) {
      const { scrollLeft, scrollWidth, clientWidth } = container
      if (leftArrowRef.current)
        leftArrowRef.current.style.display = scrollLeft > 0 ? "flex" : "none"
      if (rightArrowRef.current)
        rightArrowRef.current.style.display =
          scrollLeft < scrollWidth - clientWidth ? "flex" : "none"
    }
  }, [scrollable])

  const scrollTabs = (direction: "left" | "right") => {
    const container = tabsRef.current
    if (container && scrollable) {
      const scrollAmount =
        direction === "left" ? -container.clientWidth : container.clientWidth

      container.scrollBy({ left: scrollAmount, behavior: "smooth" })
    }
  }

  useEffect(() => {
    if (!scrollable || !tabsContainerRef.current) {
      return
    }

    const observer = new ResizeObserver(() => {
      checkForScroll()
    })

    observer.observe(tabsContainerRef.current)

    return () => {
      observer.disconnect()
    }
  }, [scrollable, checkForScroll])

  return (
    <div
      ref={tabsContainerRef}
      className={cn("flex flex-row items-center gap-4 scroll-px-16", {
        "overflow-y-hidden": scrollable,
      })}
    >
      {children && (
        <div
          className={cn("flex flex-row gap-4 relative overflow-x-clip", {
            "overflow-y-hidden": scrollable,
          })}
        >
          {scrollable && (
            <DMButtonWhite
              ref={leftArrowRef}
              onClick={() => scrollTabs("left")}
              className="absolute left-1 top-1/2 -translate-y-1/2 z-2"
              icon={IconArrowLeft}
              size="small"
              style={{
                display: "none",
              }}
            />
          )}
          <div
            ref={tabsRef}
            onScroll={checkForScroll}
            className={cn(
              "flex flex-1 flex-row gap-4 no-scrollbar scroll-px-16",
              {
                "relative top-[10px] -mt-[10px] overflow-x-scroll pb-12 px-8":
                  scrollable,
              },
              className
            )}
          >
            <DMTabListContextDeprecated.Provider value={contextValue}>
              {children || null}
            </DMTabListContextDeprecated.Provider>
          </div>
          {scrollable && (
            <DMButtonWhite
              ref={rightArrowRef}
              onClick={() => scrollTabs("right")}
              className="absolute right-1 top-1/2 -translate-y-1/2 z-2"
              icon={IconArrowRight}
              size="small"
              style={{
                display: "none",
              }}
            />
          )}
        </div>
      )}
      {endSlot || null}
    </div>
  )
}
