import React from "react"

import { ActionableRef } from "../../Actionable/Actionable.types"
import { Button } from "../../primitives/Button"
import { DMButtonBaseProps } from "../../primitives/Button/Button.types"
import { VariantClasses } from "../DMButton.types"

type Props = Omit<DMButtonBaseProps, "variant">

function DMButtonEmphasizedBase(props: Props, ref: ActionableRef) {
  const {
    size = "medium",
    isFullWidth = false,
    isLoading = false,
    isDisabled = false,
    isHighlighted = false,
    children,
    ...rest
  } = props

  const variantClasses: VariantClasses = {
    solid: {
      base: "bg-black text-white",
      hover: "hover:text-white",
      focus: "focus-visible:ring-2",
      highlighted: "text-white",
    },
  }

  return (
    <Button
      {...rest}
      ref={ref}
      isDisabled={isDisabled}
      isFullWidth={isFullWidth}
      isHighlighted={isHighlighted}
      isLoading={isLoading}
      size={size}
      variant="solid"
      variantStyles={variantClasses.solid}
    >
      {children || ""}
    </Button>
  )
}

const DMButtonEmphasized = React.forwardRef(
  DMButtonEmphasizedBase
) as React.ForwardRefExoticComponent<Props>

DMButtonEmphasized.displayName = "DMButtonEmphasized"

export { DMButtonEmphasized }
