import {
  DMListboxTreeItemStructure,
  DMListboxTreeItemStructureState,
  FlatDMListboxTreeItemStructure,
  UpdateParentSelectionStates,
} from "./types"

type UpdateParentSelectionStatesOptions = {
  /** If true then the expanded items state is updated based on selection status */
  shouldUpdateExpandedItems?: boolean
}

/*
  Native functions to help with DMListboxTree component
 */
export class DMTreeUtils {
  /*
  Get all children of the item, recursively for all children of children, but not if isSelectable is false
 */
  static getAllSelectableChildren = (
    items: DMListboxTreeItemStructure[] | undefined,
    id: string
  ): string[] => {
    const item = items?.find((item) => item.id === id)
    if (!item) return []

    const children = item.children?.map((child) => {
      if (child?.isSelectable === false)
        return this.getAllSelectableChildren(item.children, child.id)

      return [
        child.id,
        ...this.getAllSelectableChildren(item.children, child.id),
      ]
    })

    return children?.flat() || []
  }

  /*
  Get all expanded children of the item
 */
  static getAllExpandedChildren = (
    items: DMListboxTreeItemStructureState[] | undefined,
    id: string | undefined
  ): string[] => {
    const item = items?.find((item) => item.id === id)
    if (!item) return [] // Return empty array if the item is not found or not expanded

    const children = item.children?.reduce((acc: string[], child) => {
      if (child.isExpanded) {
        acc.push(child.id) // Add the child ID if it's expanded
        // Recursively get expanded children of this child, if any
        acc.push(
          ...this.getAllExpandedChildren(
            item.children as DMListboxTreeItemStructureState[],
            child.id
          )
        )
      }
      return acc
    }, [])

    return children || []
  }

  /*
 Get all children of the item from flattened tree, recursively for all children of children, but not if isSelectable is false
*/
  static getAllSelectableChildrenWhenFlattened = (
    items: FlatDMListboxTreeItemStructure[] | undefined,
    childrenIds: string[] | undefined
  ): string[] => {
    if (!childrenIds) return []

    const matchingItems = items?.filter((item) => childrenIds.includes(item.id))
    if (!matchingItems) return []

    const children = matchingItems.map((item) => {
      if (item.isSelectable === false)
        return this.getAllSelectableChildrenWhenFlattened(
          items,
          item.childrenIds
        )

      return [
        item.id,
        ...this.getAllSelectableChildrenWhenFlattened(items, item.childrenIds),
      ]
    })

    return children?.flat() || []
  }

  /*
  Helper function that helps to decide if to add or remove the children from selectedValue
   */
  static addOrExtractFromOriginalValue = (
    originalValue: string[],
    newValue: string[]
  ) => {
    if (!DMTreeUtils.isValuePartOfOriginalValue(newValue, originalValue)) {
      // remove all children from selectedValue
      return originalValue.filter((value) => !newValue.includes(value))
    }
    return [...new Set([...originalValue, ...newValue].flat())]
  }

  /*
  Check if the selected value is within the limit
   */
  static isPartOfTheValue = (id: string, value: string | string[]) => {
    // check if passed value is array
    if (value instanceof Array) {
      return value.includes(id)
    }

    return value === id
  }

  static flattenTreeForVirtualization(
    treeItems: DMListboxTreeItemStructure[] | FlatDMListboxTreeItemStructure[],
    level = 0
  ): FlatDMListboxTreeItemStructure[] {
    const childItems: FlatDMListboxTreeItemStructure[] = []

    treeItems.forEach((treeItem) => {
      const { id, children: treeItemChildren, ...rest } = treeItem
      const flatMenuItem: FlatDMListboxTreeItemStructure = {
        id,
        level,
        parentIds: [],
        children: treeItemChildren,
        childrenIds: treeItemChildren?.map((child) => child.id),
        ...rest,
      }

      const flattenedChildItems = this.flattenTreeForVirtualization(
        treeItemChildren || [],
        level + 1
      )

      // Fill parent ids for children only, because root groups are not part of this hierarchy in the UI
      flattenedChildItems.forEach((child) => {
        child.parentIds.push(id)
      })

      childItems.push(flatMenuItem)

      childItems.push(...flattenedChildItems)
    })

    return childItems
  }

  /*
  Add selection state to each item, recursively for all children of children
   */
  static addInternalItemStateToAllTreeItems = (
    items: DMListboxTreeItemStructure[],
    value: string | string[],
    expandedItems: string[] = [] // Pass expandedItems as an additional parameter
  ): DMListboxTreeItemStructureState[] => {
    return items.map(
      (item: DMListboxTreeItemStructure): DMListboxTreeItemStructureState => {
        const selectionState = this.isPartOfTheValue(item.id, value)
          ? "selected"
          : "unselected"

        // Determine if the item is expanded either by its default state or if it's included in the expandedItems
        const isExpanded = expandedItems.includes(item.id)
        const allChildren = this.getAllSelectableChildren(items, item.id)

        // Process children if they exist
        let children: DMListboxTreeItemStructureState[] = []
        if (item.children) {
          // Recursively add selection state to children
          children = this.addInternalItemStateToAllTreeItems(
            item.children,
            value,
            expandedItems
          )
          // Set shouldRender for direct children based on the parent's expanded state
          if (isExpanded) {
            children = children.map((child) => ({
              ...child,
              shouldRender: true, // Ensure direct children shouldRender is true if parent is expanded
            }))
          }
        }

        // We count the selectable parent to totals if it's also selectable
        const selectableParent = item.isSelectable && item.children ? 1 : 0

        // Assume shouldRender might depend on whether the item is expanded or not
        const shouldRender = isExpanded

        return {
          ...item,
          isSelected: selectionState === "selected",
          childrenSelectionState: "none",
          isExpanded,
          isSelectable: item.isSelectable ?? true,
          shouldRender,
          childrenCount: {
            total: allChildren.length + selectableParent || 0,
            selected: 0,
          },
          children,
        }
      }
    )
  }

  /*
  Check if the value is part of the selection — if yes, we allow it to be unselected
   */
  static checkSelectionLimit(
    value: string,
    limit: number | undefined,
    selectedValues: string[] | string
  ): boolean {
    if (limit === undefined || limit === 1) {
      return false
    }

    // Check if the value is part of the selection — if yes, we allow it to be unselected
    const isNotSelectedYet = !selectedValues.includes(value)

    // Check if the count of items in selectedValue is less than the limit
    const isBelowLimit = selectedValues.length < limit

    return isNotSelectedYet && !isBelowLimit
  }

  /*
  Check if the value is part of the selection — if yes, we allow it to be unselected
  */
  static isValuePartOfOriginalValue = (
    newValue: string[],
    originalValue: string[]
  ): boolean => {
    return newValue.some((child) => !originalValue.includes(child))
  }

  /*
     Take allFlattenedItems and set shouldRender for all matchingItems
   */
  static setShouldRenderForMatchingItems(
    allFlattenedItems: FlatDMListboxTreeItemStructure[],
    matchingItems: FlatDMListboxTreeItemStructure[]
  ) {
    return allFlattenedItems.map((item: FlatDMListboxTreeItemStructure) => {
      // Matching items in case there is nothing being search
      const noSearchMatching =
        allFlattenedItems.length === matchingItems.length &&
        (item.level === 0 || item.shouldRender)

      // Matching items in case there is something being searched
      const searchMatching =
        allFlattenedItems.length !== matchingItems.length &&
        !!matchingItems.find((matchingItem) => matchingItem.id === item.id)

      return {
        ...item,
        shouldRender: noSearchMatching || searchMatching,
      }
    })
  }

  static updateParentSelectionStates(
    items: DMListboxTreeItemStructureState[],
    originalExpandedItems: string[] = [],
    {
      shouldUpdateExpandedItems = true,
    }: UpdateParentSelectionStatesOptions = {}
  ): UpdateParentSelectionStates {
    const indeterminateItems: string[] = []
    const allChildrenSelectedItems: string[] = []
    const expandedItemsBySelection: string[] = []

    // Recursive function to check/update node and count selected children
    function updateTreeSelectionState(
      node: DMListboxTreeItemStructureState
    ): DMListboxTreeItemStructureState {
      if (!node.children || node.children.length === 0) {
        return node // Return as is if no children
      }

      let selectedCount = 0

      // Update children recursively and count selected ones
      node.children = (node.children as DMListboxTreeItemStructureState[]).map(
        (child: DMListboxTreeItemStructureState) => {
          const updatedChild = updateTreeSelectionState({
            ...child,
            childrenSelectionState: "none", // Default state
            isSelected: child.isSelected, // Keep isSelected as is
          })

          if (updatedChild.isSelected && updatedChild.isSelectable)
            selectedCount += 1

          // For counting nested selections correctly
          if (
            updatedChild.childrenCount &&
            updatedChild.childrenCount.selected
          ) {
            selectedCount += updatedChild.childrenCount.selected
          }

          return updatedChild
        }
      )

      node.childrenCount = {
        selected: selectedCount,
        total: node.childrenCount?.total,
      }

      // Determine selection state based on counts
      if (selectedCount === 0) {
        node.childrenSelectionState = "none"
        return node
      }

      if (shouldUpdateExpandedItems) {
        node.isExpanded = true
        expandedItemsBySelection.push(node.id)
      }

      if (selectedCount === node.childrenCount?.total) {
        node.childrenSelectionState = "all"
        allChildrenSelectedItems.push(node.id)
      } else {
        node.childrenSelectionState = "some"
        indeterminateItems.push(node.id)
      }

      return node
    }

    // Iterate over items and update their state
    items.forEach((node) => updateTreeSelectionState(node))

    return {
      items,
      indeterminateItems,
      allChildrenSelectedItems,
      expandedItems: shouldUpdateExpandedItems
        ? Array.from(
            // add values to set to eliminate possible duplicate values - e.g. originally expanded and expanded because of selected child
            new Set(...originalExpandedItems, expandedItemsBySelection)
          )
        : originalExpandedItems,
    }
  }
}
