import React from "react"

import { cn } from "../../utils/cn"
import { DMDivider } from "../DMDivider"

import { DMDialogContent, DMDialogHeader, DMDialogFooter } from "./DMDialog"

export function DMMultiColumnDialogContent({
  firstColumnSlot,
  secondColumnSlot,
  header,
  footer,
  className,
}: {
  firstColumnSlot: React.ReactNode
  secondColumnSlot: React.ReactNode
  header: React.ReactNode
  footer: React.ReactNode
  className?: string
}) {
  return (
    <DMDialogContent
      size="large"
      className={cn("p-0 max-h-[85vh] flex flex-col", className)}
    >
      <DMDialogHeader className="px-24 py-20">{header}</DMDialogHeader>
      <DMDivider />
      <div className="flex h-full flex-1 min-h-0">
        <div className="flex-1 px-24 pt-24 min-w-0">{firstColumnSlot}</div>
        <DMDivider className="h-auto" orientation="vertical" />
        <div className="basis-1/3 grow-0 shrink-0 flex flex-col items-stretch overflow-hidden">
          <div className="p-24 pr-[13px] flex-1 overflow-y-auto h-full scrollbar-fancy scrollbar-stable">
            {secondColumnSlot}
          </div>
          <DMDivider />
          <DMDialogFooter className="p-24">{footer}</DMDialogFooter>
        </div>
      </div>
    </DMDialogContent>
  )
}
