import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

const DMTable = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="w-full overflow-auto">
    <table
      ref={ref}
      className={cn("text-body relative w-full", className)}
      {...props}
    />
  </div>
))

DMTable.displayName = "DMTable"

const DMTableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn(
      "[&_tr]:bg-transparent [&_tr]:hover:bg-transparent",
      className
    )}
    {...props}
  />
))

DMTableHeader.displayName = "DMTableHeader"

const DMTableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn("[&_tr:last-child]:border-0", className)}
    {...props}
  />
))

DMTableBody.displayName = "DMTableBody"

const DMTableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn("bg-primary text-primary-foreground font-medium", className)}
    {...props}
  />
))

DMTableFooter.displayName = "DMTableFooter"

const DMTableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & {
    // If the row should have hover effect or not
    isInteractive?: boolean
  }
>(({ className, isInteractive = true, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "group/row border-neutral-faded border-b transition-colors",
      isInteractive && "hover:bg-neutral-faded",
      className
    )}
    {...props}
  />
))

DMTableRow.displayName = "DMTableRow"

const DMTableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "bg-blur-neutral-faded text-neutral-faded text-body-small sticky top-0 p-8 px-12 text-left font-semibold",
      className
    )}
    {...props}
  />
))

DMTableHead.displayName = "DMTableHead"

const DMTableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & {
    children?: React.ReactNode
    vAlign?: "top" | "middle"
  }
>(({ className, children, vAlign = "top", ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "p-12 [&>button]:-my-4",
      vAlign === "top" && "align-top",
      vAlign === "middle" && "align-middle",
      className
    )}
    {...props}
  >
    {children}
  </td>
))

DMTableCell.displayName = "DMTableCell"

const DMTableCellActions = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & {
    children?: React.ReactNode
    onHoverChildren?: React.ReactNode
    vAlign?: "top" | "middle"
  }
>(({ className, children, onHoverChildren, vAlign = "top", ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      "p-12 py-8",
      vAlign === "top" && "align-top",
      vAlign === "middle" && "align-middle",
      className
    )}
    {...props}
  >
    <div className="inline-flex flex-row gap-8">
      {children}
      {onHoverChildren && (
        <div className="opacity-0 transition-opacity has-[:focus-visible]:opacity-100 group-hover/row:opacity-100 has-dropdown-open:opacity-100">
          {onHoverChildren}
        </div>
      )}
    </div>
  </td>
))

DMTableCellActions.displayName = "DMTableCellActions"

export {
  DMTable,
  DMTableHeader,
  DMTableBody,
  DMTableFooter,
  DMTableHead,
  DMTableRow,
  DMTableCell,
  DMTableCellActions,
}
