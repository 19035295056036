/**
 * Extracts the first letter of the first two words from a name. It correctly handles
 * Unicode characters, including emojis. If there is only one word, it returns its first character.
 * Trims the name and handles surrogate pairs, so emojis are not split.
 *
 * Examples:
 * - getFirstLetters("Jane Doe") returns "JD"
 * - getFirstLetters("山田太郎") returns "山"
 * - getFirstLetters("👨‍🚀👩‍🚀") returns "👨"
 *
 * @param {string} [name] - The name to extract initials from.
 * @returns {string} The initials extracted from the name.
 */
export const getFirstLetters = (name?: string): string => {
  // Spread the name into an array of Unicode characters
  const words = (name || "")
    .trim()
    .split(/\s+/)
    .filter((word) => word.length > 0)

  if (words.length === 0) {
    return ""
  }

  // Use Array.from to correctly get the first two Unicode characters
  return `${Array.from(words[0] || "")[0] || ""}${
    Array.from(words[1] || "")[0] || ""
  }`
}
