import React, { ReactNode } from "react"

import { DMIcon, normalizeTestId } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import type { IconType } from "@supernovaio/icons/types"

import Actionable from "../Actionable/Actionable"
import { Props as IconProps } from "../DMIcon/DMIcon.types"
import { DMNavItemBase, DMNavItemBaseProps } from "../DMNavItemBase"

export interface DMNavItemProps
  extends Pick<
    DMNavItemBaseProps,
    | "size"
    | "startSlot"
    | "endSlot"
    | "className"
    | "onPointerEnter"
    | "onPointerLeave"
  > {
  actionableTag?: React.ElementType
  icon?: IconType
  itemBaseClassNames?: string
  itemClassNames?: string
  iconColor?: IconProps["color"]
  text: React.ReactNode
  useRegularWeightFont?: boolean
  textClassNames?: string
  count?: number | React.ReactNode
  isSelected?: boolean
  isDisabled?: boolean
  onClick?: React.MouseEventHandler<any>
  href?: string
  dataTestId?: string
}

const DMNavItem = React.forwardRef<
  React.ElementRef<typeof Actionable>,
  DMNavItemProps
>(function DMNavItem(
  {
    actionableTag,
    icon,
    iconColor,
    itemBaseClassNames,
    itemClassNames,
    startSlot,
    text,
    useRegularWeightFont = false,
    count,
    href,
    endSlot,
    isSelected = false,
    isDisabled = false,
    textClassNames,
    onClick,
    size = "small",
    dataTestId,
    ...rest
  },
  ref
) {
  const textAuxClassNames = cn(
    {
      "opacity-disabled": isDisabled,
      "text-primary": isSelected,
    },
    textClassNames
  )

  return (
    <Actionable
      actionableTag={actionableTag}
      className={cn(
        "group flex w-full rounded ring-offset-1 text-neutral no-underline relative",
        "focus-visible:ring-2 focus-visible:z-2",
        textAuxClassNames,
        {
          "hover:bg-neutral": !isDisabled,
          "bg-primary-faded hover:bg-primary-faded": isSelected,
        }
      )}
      href={href}
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      data-test-id={normalizeTestId(dataTestId)}
      {...rest}
    >
      <DMNavItemBase
        className={itemBaseClassNames}
        countSlot={
          count !== undefined ? (
            <DMNavItemCountSlot count={count} isSelected={isSelected} />
          ) : null
        }
        endSlot={endSlot}
        iconSlot={
          icon ? <DMIcon color={iconColor} size={size} svg={icon} /> : null
        }
        itemClassNames={itemClassNames}
        size={size}
        startSlot={startSlot}
        textSlot={
          <div
            className={cn(
              "text-body line-clamp-1 min-w-[96px] flex-grow flex items-center gap-4 text-start",
              useRegularWeightFont ? "font-normal" : "font-semibold",
              textAuxClassNames
            )}
          >
            {text}
          </div>
        }
      />
    </Actionable>
  )
})

type DMNavItemCountSlotProps = {
  count: ReactNode
  isSelected: boolean
} & React.HTMLAttributes<HTMLDivElement>

/** There are rare cases where we need to manually create the count component outside the count slot,
 * (eg. when it needs to be hidden on hover) so we need it as a standalone component */
export function DMNavItemCountSlot({
  className,
  count,
  isSelected,
}: DMNavItemCountSlotProps) {
  return (
    <div
      className={cn(
        className,
        "text-body-small text-neutral-faded group-hover:text-neutral-faded line-clamp-1 font-semibold",
        isSelected && "text-neutral-inherit group-hover:text-neutral-inherit"
      )}
    >
      {count}
    </div>
  )
}

export default DMNavItem
