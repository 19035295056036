import * as React from "react"
import { SVGProps } from "react"

export const IconDeselectAll = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M8.147 13.794H3.912A1.41 1.41 0 0 1 2.5 12.382v-8.47A1.41 1.41 0 0 1 3.912 2.5h8.47a1.41 1.41 0 0 1 1.412 1.412v4.235m-7.765 0L7.441 9.56l2.824-2.824m0 5.647H14.5"
    />
  </svg>
)
