"use client"

import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import * as SliderPrimitive from "@radix-ui/react-slider"

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      "relative flex items-center select-none touch-none w-full h-icon-small",
      className
    )}
    {...props}
  >
    <SliderPrimitive.Track className="relative grow rounded-[4px] bg-neutral-highlighted h-[4px]">
      <SliderPrimitive.Range className="absolute bg-primary rounded-[4px] h-full" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="block w-icon-small h-icon-small bg-primary rounded-full focus-visible:ring-2 outline-0" />
  </SliderPrimitive.Root>
))
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider as DMSlider }
