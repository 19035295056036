import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { SupernovaLogoFull } from "./assets/LogoFull"
import { SupernovaLogoSymbol } from "./assets/LogoSymbol"

export type DMSupernovaLogoProps = {
  size?: "small" | "medium" | "large"
  type?: "symbol" | "full"
}

export function DMSupernovaLogo(props: DMSupernovaLogoProps) {
  const { size = "medium", type = "full" } = props

  return type === "full" ? (
    <SupernovaLogoFull
      aria-label="Supernova Logo"
      className={cn("flex-inline h-icon-medium w-auto", {
        "h-icon-small": size === "small",
      })}
    />
  ) : (
    <SupernovaLogoSymbol
      aria-label="Supernova Logo"
      className={cn("flex-inline ", {
        "h-icon-small w-icon-small": size === "small",
        "h-icon-medium w-icon-medium": size === "medium",
        "h-icon-large w-icon-large": size === "large",
      })}
    />
  )
}
